import { Trans } from 'react-i18next'; // 1. react-i18next import 


// 서버에 있는 이미지 경로를 리턴하는 함수
export function returnImgSrc (ImgPath, ImgFileName) 
{      
    let ServerName = process.env.REACT_APP_IMG_URL;
    let retunValue = "";

    if (ImgPath !== "" && ImgFileName !== "")
      retunValue = ServerName + ImgPath + ImgFileName;

    return retunValue;
}

// 카테고리 이름을 리턴하는 함수
export function returnCategoryName (category_array, category_idx) 
{   
    // console.log("returnCategoryName : category_array : ", category_array, " category_idx : ", category_idx);   
    let retunValue = "";

    for (var key in category_array)
    {
        if (category_array[key].category_idx === category_idx)
        {
            retunValue = category_array[key].category_name;
            break;
        }
    }

    return retunValue;
}

// 천단위 콤마를 찍어주는 함수
export function priceToString(price) 
{
    if (price === null)
        price = 0;

    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

// 주문상태를 문자열로 돌려주는 함수
export function returnOrderState(state) 
{
    let returnValue = "";

    // 주문상태코드 / 결제대기(W : wait), 결제오류(IA : inactive), 결제완료(A : active), 무료쿠폰(F : free)
    // switch (state)
    // {
    //     case "W":
    //         returnValue = "결제대기";
    //         break;

    //     case "IA":
    //         returnValue = "결제오류";
    //         break;

    //     case "A":
    //         returnValue = "결제완료";
    //         break;

    //     case "F":
    //         returnValue = "무료쿠폰";
    //         break;

    //     default:
    //         returnValue = "주문에러";
    // }

    switch (state)
    {
        case "W":
            returnValue = <Trans i18nKey="MypagePayInfo.string13"/>;
            break;

        case "IA":
            returnValue = <Trans i18nKey="MypagePayInfo.string14"/>;
            break;

        case "A":
            returnValue = <Trans i18nKey="MypagePayInfo.string15"/>;
            break;

        case "F":
            returnValue = <Trans i18nKey="MypagePayInfo.string16"/>;
            break;

        default:
            returnValue = <Trans i18nKey="MypagePayInfo.string17"/>;
    }

    return returnValue;
}

// 결제상태를 문자열로 돌려주는 함수
// *** 결제상태 ***
// ready(브라우저 창 이탈, 가상계좌 발급 완료 등 미결제 상태), 
// paid(결제완료), 
// failed(신용카드 한도 초과, 체크카드 잔액 부족, 브라우저 창 종료 또는 취소 버튼 클릭 등 결제실패 상태)
export function returnPaymentState(state) 
{
    let returnValue = "";

    
    // switch (state)
    // {
    //     case "ready":
    //         returnValue = "미결제";
    //         break;

    //     case "paid":
    //         returnValue = "결제완료";
    //         break;

    //     case "failed":
    //         returnValue = "결제실패";
    //         break;

    //     default:
    //         returnValue = "결제에러";
    // }

    switch (state)
    {
        case "ready":
            returnValue = <Trans i18nKey="MypagePayInfo.string18"/>;
            break;

        case "paid":
            returnValue = <Trans i18nKey="MypagePayInfo.string15"/>;
            break;

        case "failed":
            returnValue = <Trans i18nKey="MypagePayInfo.string19"/>;
            break;

        default:
            returnValue = <Trans i18nKey="MypagePayInfo.string14"/>;
    }

    return returnValue;
}

// 환불상태를 문자열로 돌려주는 함수
// *** 환불 상태 ***
// 환불상태 / cancelled(환불), failed(실패 - 에러메세지 참조)
export function returnRefundState(state) 
{
    let returnValue = "";

    // switch (state)
    // {
    //     case "cancelled":
    //         returnValue = "환불완료";
    //         break;

    //     case "failed":
    //         returnValue = "환불실패";
    //         break;

    //     default:
    //         returnValue = "결제에러";
    // }

    switch (state)
    {
        case "cancelled":
            returnValue = <Trans i18nKey="MypagePayInfo.string20"/>;
            break;

        case "failed":
            returnValue = <Trans i18nKey="MypagePayInfo.string21"/>;
            break;

        default:
            returnValue = <Trans i18nKey="MypagePayInfo.string14"/>;
    }

    return returnValue;
}


export function dateChangeReturn(changeDate)
{
    let returnValue = "";

    // console.log("changeDate : ", changeDate);
    let now = changeDate?.replace(/-/g, "/"); // now: '2021/02/13 17:40:00'

    if (changeDate !== null && changeDate !== undefined)
    {
        const returnDate = new Date(now);
        const year = returnDate.getFullYear(); 
        const month = returnDate.getMonth() + 1; 
        const day = returnDate.getDate();
        returnValue = year + '.' + month + '.' + day;
        // console.log("year : ", year);
        // console.log("month : ", month);
        // console.log("day : ", day);
        // console.log("date : ", returnValue);
    }
    else
    {
        returnValue = '';
    }
	
	return returnValue;
};


// 권한을 체크하는 함수
export function checkAuth (userInfo) {
	
	if (!userInfo.isLoggedIn)
	{
		return false;
	}
	else
	{
		if (userInfo.user.type !== 1)
			return false
	}
		
	return true;
}

// 결제 상세 페이지에서 날짜의 포멧을 바꿔주는 함수
// 2022-06-17 15:16:54 -> 2022.03.25 - 16시35분
export function payDateChangeReturn(changeDate)
{
    let returnValue = "";
    let now = changeDate?.replace(/-/g, "/"); // now: '2021/02/13 17:40:00'

    if (changeDate !== null && changeDate !== undefined)
    {
        const returnDate = new Date(now);
        const year = returnDate.getFullYear(); 
        const month = returnDate.getMonth() + 1; 
        const day = returnDate.getDate();
        const hour = returnDate.getHours();
        const minute = returnDate.getMinutes();

        if (localStorage.getItem("language") === 'ko')
            returnValue = `${year}.${month}.${day} - ${hour}시 ${minute}분`;
        else
            returnValue = `${year}.${month}.${day} - ${hour}:${minute}`;
        // console.log("date : ", returnValue);
    }
    else
    {
        returnValue = '';
    }
	
	return returnValue;
};

// 몇 퍼센트인지 구하기
// 35는 350의 몇 퍼센트인지 구하기
// percent(35, 350)
export function percent(par,total) 
{
    return (par / total) * 100;
}

// 결제수단을 문자열로 돌려주는 함수
// *** 결제 수단 ***
// 결제수단 / card(카드), trans(실시간계좌이체), vbank(가상계좌 - 무통장입금)
export function returnPayOptCdState(state) 
{
    let returnValue = "";

    // switch (state)
    // {
    //     case "card":
    //         returnValue = "카드";
    //         break;

    //     case "trans":
    //         returnValue = "실시간계좌이체";
    //         break;

    //     case "vbank":
    //         returnValue = "가상계좌";
    //         break;

    //     default:
    //         returnValue = "기타";
    // }

    switch (state)
    {
        case "card":
            returnValue = <Trans i18nKey="MypagePayInfo.string22"/>;
            break;

        case "trans":
            returnValue = <Trans i18nKey="MypagePayInfo.string23"/>;
            break;

        case "vbank":
            returnValue = <Trans i18nKey="MypagePayInfo.string24"/>;
            break;

        default:
            returnValue = <Trans i18nKey="MypagePayInfo.string25"/>;
    }

    return returnValue;
}


// 쿠폰의 상태값을 문자열로 돌려주는 함수
// *** 쿠폰 상태 ***
// 사용대기(W : wait), 사용중(A : active), 중지상태(S : Stop)
export function returnCouponState(state) 
{
    let returnValue = "";

    // switch (state)
    // {
    //     case "W":
    //         returnValue = "사용대기";
    //         break;

    //     case "A":
    //         returnValue = "사용중";
    //         break;

    //     case "S":
    //         returnValue = "중지상태";
    //         break;

    //     default:
    //         returnValue = "기타";
    // }

    switch (state)
    {
        case "W":
            returnValue = <Trans i18nKey="MypageCoupon.string5"/>;
            break;

        case "A":
            returnValue = <Trans i18nKey="MypageCoupon.string6"/>;
            break;

        case "S":
            returnValue = <Trans i18nKey="MypageCoupon.string7"/>;
            break;

        default:
            returnValue = <Trans i18nKey="MypageCoupon.etc"/>;
    }

    return returnValue;
}

// D-day 계산기
export function retunDday(Dday)
{
    let returnString = '';
    
    let Dday2 = Dday?.replace(/-/g, "/"); // now: '2021/02/13 17:40:00'
    let dday = new Date(Dday2).getTime(); //디데이
    let now = new Date(); //현재 날짜 가져오기
    let distance = dday - now;
    let d = Math.floor(distance / (1000 * 60 * 60 * 24));

    
    // if (new Date(Dday) < now)   // 만료가 되었다면
    //     returnString = '만료됨';
    // else
    //     returnString = 'D-' + d;
    
    // 만료됬는지 체크함
    if (new Date(Dday) < now)   // 만료가 되었다면
        returnString = <Trans i18nKey="Common.Expired"/>;
    else
        returnString = 'D-' + d;

    return returnString;
}

// 문의하기 문의유형을 문자열로 돌려주는 함수
export function returnSolutionType(type) 
{
    let returnValue = "";

    // switch (type)
    // {
    //     case 1:
    //         returnValue = "홈페이지";
    //         break;

    //     case 2:
    //         returnValue = "학습(앱)";
    //         break;

    //     case 3:
    //         returnValue = "결제";
    //         break;

    //     default:
    //         returnValue = "기타";
    // }

    switch (type)
    {
        case 1:
            returnValue = <Trans i18nKey="FQA.category.cate3"/>;
            break;

        case 2:
            returnValue = <Trans i18nKey="FQA.category.cate2"/>;
            break;

        case 3:
            returnValue = <Trans i18nKey="FQA.category.cate1"/>;
            break;

        default:
            returnValue = <Trans i18nKey="FQA.category.cate4"/>;
    }

    return returnValue;
}

// 미션의 등급을 문자열로 돌려주는 함수
export function returnMissionRating(state) 
{
    let returnValue = "";

    // 등급 (초급: B(beginner), 중급 : M(middle) , 고급 :  H(high))
    // switch (state)
    // {
    //     case "B":
    //         returnValue = "초급";
    //         break;

    //     case "M":
    //         returnValue = "중급";
    //         break;

    //     case "H":
    //         returnValue = "고급";
    //         break;

    //     default:
    //         returnValue = "";
    // }

    switch (state)
    {
        case "B":
            returnValue = <Trans i18nKey="Common.beginner"/>;
            break;

        case "M":
            returnValue = <Trans i18nKey="Common.middle"/>;
            break;

        case "H":
            returnValue = <Trans i18nKey="Common.high"/>;
            break;

        default:
            returnValue = "";
    }

    return returnValue;
}

// 인앱 상태를 문자열로 돌려주는 함수
export function returnInappStatus(state) 
{
    let returnValue = "";

    // 상태코드 / 결제대기(W : wait), 결제오류(IA : inactive), 결제완료(A : active)
    // switch (state)
    // {
    //     case "W":
    //         returnValue = "결제대기";
    //         break;

    //     case "IA":
    //         returnValue = "결제오류";
    //         break;

    //     case "A":
    //         returnValue = "결제완료";
    //         break;
            
    //     case "R":
    //         returnValue = "환불";
    //         break;

    //     default:
    //         returnValue = "";
    // }

    switch (state)
    {
        case "W":
            returnValue = <Trans i18nKey="MypagePayInfo.string13"/>;
            break;

        case "IA":
            returnValue = <Trans i18nKey="MypagePayInfo.string14"/>;
            break;

        case "A":
            returnValue = <Trans i18nKey="MypagePayInfo.string15"/>;
            break;
            
        case "R":
            returnValue = <Trans i18nKey="Common.Refund"/>;
            break;

        default:
            returnValue = "";
    }

    return returnValue;
}

// 인앱 디테일 상태를 문자열로 돌려주는 함수
export function returnInappDetailStatus(state) 
{
    let returnValue = "";

    // 상품의 상태코드 / 사용대기(W : wait), 사용중지(IA : inactive), 사용중(A : active)
    // switch (state)
    // {
    //     case "W":
    //         returnValue = "사용대기";
    //         break;

    //     case "IA":
    //         returnValue = "사용중지";
    //         break;

    //     case "A":
    //         returnValue = "사용중";
    //         break;

    //     default:
    //         returnValue = "";
    // }

    switch (state)
    {
        case "W":
            returnValue = <Trans i18nKey="MypagePayInfo.string49"/>;
            break;

        case "IA":
            returnValue = <Trans i18nKey="MypagePayInfo.string50"/>;
            break;

        case "A":
            returnValue = <Trans i18nKey="MypagePayInfo.string51"/>;
            break;

        default:
            returnValue = "";
    }

    return returnValue;
}

// 주문상태를 문자열로 돌려주는 함수
export function returnGroupPaymentState(state) 
{
    let returnValue = "";

    // 그룹 결제 상태코드 / wait(결제대기), confirm(입금확인 요청) active(결제완료) cancel(취소)
    // switch (state)
    // {
    //     case "wait":
    //         returnValue = "결제대기";
    //         break;

    //     case "confirm":
    //         returnValue = "입금확인 요청";
    //         break;

    //     case "active":
    //         returnValue = "결제완료";
    //         break;

    //     case "cancel":
    //         returnValue = "결제취소";
    //         break;

    //     default:
    //         returnValue = "결제에러";
    // }

    switch (state)
    {
        case "wait":
            returnValue = <Trans i18nKey="MypagePayInfo.string13"/>;
            break;

        case "confirm":
            returnValue = <Trans i18nKey="MypagePayInfo.string47"/>;
            break;

        case "active":
            returnValue = <Trans i18nKey="MypagePayInfo.string15"/>;
            break;

        case "cancel":
            returnValue = <Trans i18nKey="MypagePayInfo.string48"/>;
            break;

        default:
            returnValue = <Trans i18nKey="MypagePayInfo.string14"/>;
    }

    return returnValue;
}

//textarea 바이트 수 체크하는 함수
export function returnCheckByte(text)
{

    const maxByte = 100; //최대 100바이트
    const text_val = text; //입력한 문자
    const text_len = text_val.length; //입력한 문자수
    
    let totalByte=0;

    for(let i=0; i<text_len; i++)
    {
    	const each_char = text_val.charAt(i);
        const uni_char = escape(each_char); //유니코드 형식으로 변환
        
        if(uni_char.length>4)
        {
        	// 한글 : 2Byte
            totalByte += 2;
        }
        else
        {
        	// 영문,숫자,특수문자 : 1Byte
            totalByte += 1;
        }
    }

    return totalByte;
}