import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, CardActions } from '@mui/material';

import Stack from '@mui/material/Stack';

import TextareaAutosize from '@mui/material/TextareaAutosize';

import styles from "../../css/common.module.css";

import { useCallback } from 'react';

import { useTranslation, Trans } from 'react-i18next'; // 1. react-i18next import 
import { useDispatch, useSelector } from "react-redux";

import { classHomeworkSelectAll, classHomeworkCommentSelect, classHomeworkCommentInsert, classHomeworkListAll, classHomeworkCommentDelete } from "../../actions/class";

import * as common from "../../lib";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));  

function ConfirmationDialogRaw(props) {

  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = React.useState(valueProp);
  const radioGroupRef = React.useRef(null);
  const [commentValue, setCommentValue] = React.useState('');
  const [commentState, setCommentState] = React.useState('I');       // I(초기값), W(쓰기)
  const [commentTextLength, setCommentTextLength] = React.useState(0);      // 커멘트 길이 정보 저장
  const [commentMaxByte, setCommentMaxByte] = React.useState(512);      // 커멘트 최대 길이 512bytes

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언	

//   const homeworkComment = useSelector(state => state.classReducer.homeworkComment);
//   console.log("homeworkComment : ", homeworkComment);

  React.useEffect(() => {

    // console.log(props.RowData);

    if (!open) {
      setValue(valueProp);
    //   console.log("open : ", open);
    }
    else
    {
        // console.log("open : ", open);

        let sendData2 = {
            mis_idx: props.RowData.mis_idx,
            mst_idx: props.mstIdx,
        }
    
        dispatch(classHomeworkCommentSelect(sendData2))
        .then(data => 
        {
            // console.log("classHomeworkCommentSelect data : ", data);
            if (data === undefined)
            {
                setCommentValue("");
                setCommentTextLength(0);
            }
            else
            {
                setCommentValue(data.contents);
                setCommentTextLength(common.returnCheckByte(data.contents));
            }
        })
        .catch(e => {
            console.log(e);
        });
    }

    // let mission = props.mission;
    // console.log("mission2 => ", mission);

    // console.log(props.RowData);

    // let sendData = {
	// 	hm_idx: props.RowData.hm_idx,
	// 	mst_idx: props.mstIdx,
	// 	mis_idx: props.RowData.mis_idx,
	// }

	// 창이 열릴 때 데이터를 불러온다. (화면에 표시되는 데이터를 편집하기 위한 데이터)
	// dispatch(classHomeworkSelectAll(sendData))
	// .then(data => 
	// {
		// console.log("classHomeworkSelectAll data : ", data);

        // console.log(data.studentScore);
        // console.log(data.studentMissionScore);
        // console.log(data.homeworkMission.mis_idx);
        // console.log(data.homeworkMission.mission);

        // let mission = data.homeworkMission;

        // console.log("mission => ", mission);

        // setMission(mission);

        // 받아온 데이터를 해석해서 그려주는 루틴
        // for (let i=0; i<mission.mission.chapter.length; i++)
        // {
        //     console.log("chapter" + i);

        //     for (let j=0; j<mission.mission.chapter[i].block.length; j++)
        //     {
        //         console.log("   block" + j);
        //         console.log("       block type : " + mission.mission.chapter[i].block[j].block_type);

        //         if (mission.mission.chapter[i].block[j].block_type === "block")
        //         {
        //             console.log("       block");
        //             console.log("       block orderby : " + mission.mission.chapter[i].block[j].block_orderby);
        //             console.log("       block ID : " + mission.mission.chapter[i].block[j].block_ID);
        //             console.log("       block value : " + mission.mission.chapter[i].block[j].block_value);
        //             console.log("       block type : " + mission.mission.chapter[i].block[j].block_type);

        //         }
        //         else if (mission.mission.chapter[i].block[j].block_type === "repeat")
        //         {
        //             console.log("       repeat");
        //             console.log("       block orderby : " + mission.mission.chapter[i].block[j].block_orderby);
        //             console.log("       block ID : " + mission.mission.chapter[i].block[j].block_ID);
        //             console.log("       block value : " + mission.mission.chapter[i].block[j].block_value);
        //             console.log("       block type : " + mission.mission.chapter[i].block[j].block_type);

        //             for (let k=0; k<mission.mission.chapter[i].block[j].repeat.length; k++)
        //             {
        //                 console.log("           block orderby : " + mission.mission.chapter[i].block[j].repeat[k].block_orderby);
        //                 console.log("           block ID : " + mission.mission.chapter[i].block[j].repeat[k].block_ID);
        //                 console.log("           block value : " + mission.mission.chapter[i].block[j].repeat[k].block_value);
        //             }
        //         }
        //     }
        // }
	// })
	// .catch(e => {
	// 	console.log(e);
	// });

    // 받아온 데이터를 해석해서 그려주는 루틴
    // for (let i=0; i<mission.mission.chapter.length; i++)
    // {
    //     console.log("chapter" + i);

    //     for (let j=0; j<mission.mission.chapter[i].block.length; j++)
    //     {
    //         console.log("   block" + j);
    //         console.log("       block type : " + mission.mission.chapter[i].block[j].block_type);

    //         if (mission.mission.chapter[i].block[j].block_type === "block")
    //         {
    //             console.log("       block");
    //             console.log("       block orderby : " + mission.mission.chapter[i].block[j].block_orderby);
    //             console.log("       block ID : " + mission.mission.chapter[i].block[j].block_ID);
    //             console.log("       block value : " + mission.mission.chapter[i].block[j].block_value);
    //             console.log("       block type : " + mission.mission.chapter[i].block[j].block_type);

    //         }
    //         else if (mission.mission.chapter[i].block[j].block_type === "repeat")
    //         {
    //             console.log("       repeat");
    //             console.log("       block orderby : " + mission.mission.chapter[i].block[j].block_orderby);
    //             console.log("       block ID : " + mission.mission.chapter[i].block[j].block_ID);
    //             console.log("       block value : " + mission.mission.chapter[i].block[j].block_value);
    //             console.log("       block type : " + mission.mission.chapter[i].block[j].block_type);

    //             for (let k=0; k<mission.mission.chapter[i].block[j].repeat.length; k++)
    //             {
    //                 console.log("           block orderby : " + mission.mission.chapter[i].block[j].repeat[k].block_orderby);
    //                 console.log("           block ID : " + mission.mission.chapter[i].block[j].repeat[k].block_ID);
    //                 console.log("           block value : " + mission.mission.chapter[i].block[j].repeat[k].block_value);
    //             }
    //         }
    //     }
    // }
    
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const onCommentEditChange = (event) => {
    // console.log(event.target.value);

    const maxByte = commentMaxByte; //최대 200바이트 (한글 기준 100자)

    setCommentTextLength(common.returnCheckByte(event.target.value));

    if(commentTextLength>maxByte)
    {
    	alert('최대 ' + maxByte + 'byte까지만 입력가능합니다.');
        // document.getElementById("nowByte").innerText = totalByte;
        // document.getElementById("nowByte").style.color = "red";
    }
    else
    {
        // document.getElementById("nowByte").innerText = totalByte;
        // document.getElementById("nowByte").style.color = "green";
    }

    setCommentValue(event.target.value);
}

const onClickCommentWrite = (event) => {
    // console.log("첨삭하기");
    setCommentState("W");
}

const onClickCommentCancel = (event) => {
    // console.log("첨삭하기");
    setCommentState("I");

    let sendData2 = {
        mis_idx: props.RowData.mis_idx,
        mst_idx: props.mstIdx,
    }

    dispatch(classHomeworkCommentSelect(sendData2))
    .then(data => 
    {
        // console.log("classHomeworkCommentSelect data : ", data);
        if (data === undefined)
            setCommentValue("");
        else
            setCommentValue(data.contents);
    })
    .catch(e => {
        console.log(e);
    });
}

const onClickCommentComplete = (event) => {

    let sendData = {
		mis_idx: props.RowData.mis_idx,
		mst_idx: props.mstIdx,
        contents: commentValue,
	}

    dispatch(classHomeworkCommentInsert(sendData))
	.then(data => 
	{
		// console.log("classHomeworkCommentInsert data : ", data);
        setCommentState("I");

        let sendData2 = {
            mis_idx: props.RowData.mis_idx,
            mst_idx: props.mstIdx,
        }
    
        dispatch(classHomeworkCommentSelect(sendData2))
        .then(data => 
        {
            // console.log("classHomeworkCommentSelect data : ", data);
            if (data === undefined)
                setCommentValue("");
            else
                setCommentValue(data.contents);
        })
        .catch(e => {
            console.log(e);
        });
	})
	.catch(e => {
		console.log(e);
	});
}

const onClickCommentDelete = (event) => {

    let sendData = {
		mis_idx: props.RowData.mis_idx,
		mst_idx: props.mstIdx,
	}

    dispatch(classHomeworkCommentDelete(sendData))
	.then(data => 
	{
		// console.log("classHomeworkCommentDelete data : ", data);
        setCommentState("I");
        setCommentValue("");

	})
	.catch(e => {
		console.log(e);
	});
}

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { height: '100%' } }}
      fullWidth={true}
      maxWidth="false"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>{props.RowData.mis_id} ({props.RowData.mis_category_name}) 수행과정</DialogTitle>
      <DialogContent dividers>

        <div>
            <div>
                미션 풀이 상세정보
            </div>
            <div>

            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
                sx={{ marginBottom: 4 }}
            >
                <Item>

                <TableContainer component={Paper} sx={{ minWidth: 750, width:750 }}>
                    <Table sx={{ minWidth: 750, width:750 }} size="small" aria-label="a dense table">
                        <TableHead>
                        <TableRow>
                            <StyledTableCell>미션번호</StyledTableCell>
                            <StyledTableCell align="center">클리어횟수</StyledTableCell>
                            <StyledTableCell align="center">클리어날짜(최근)</StyledTableCell>
                            <StyledTableCell align="center">코딩 라인수</StyledTableCell>
                            <StyledTableCell align="center">이동거리</StyledTableCell>
                            <StyledTableCell align="center">미션을 푼 시간</StyledTableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">{props.RowData.mis_id}</StyledTableCell>
                            <StyledTableCell align="center">{props?.studentScore?.clear_number || 0}회</StyledTableCell>
                            <StyledTableCell align="center">{props?.missionScore?.smis_clear_date || "-"}</StyledTableCell>
                            <StyledTableCell align="center">{props?.missionScore?.smis_line_number || 0}</StyledTableCell>
                            <StyledTableCell align="center">{props?.missionScore?.smis_move_number || 0}</StyledTableCell>
                            <StyledTableCell align="center">{props?.missionScore?.smis_end_date || "-"}</StyledTableCell>
                        </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                </Item>
                <Item>

                <Card sx={{ width: 750 }}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="50"
                            image="/assets/yame_advice01.png"
                            alt="green iguana"
                        />
                        <CardContent>
                        {/* <Typography gutterBottom variant="h5" component="div">
                            첨삭 메세지
                        </Typography> */}
                        <Typography variant="body2" color="text.secondary">
                            {
                                commentState === 'W' ?
                                <TextareaAutosize 
                                    maxRows={3} 
                                    minRows={3}
                                    maxLength={commentMaxByte}
                                    style={{ width: 700 }}
                                    value={commentValue}
                                    onChange={onCommentEditChange}
                                />
                                :
                                <p style={{ fontSize: 15, whiteSpace: 'pre-line' }}>{commentValue || "데이터 없음"}</p>
                            }
                            <span style={{ fontSize: 14 }}>{commentTextLength}/{commentMaxByte} bytes <font style={{ fontSize: 14, color: 'red' }}>(한글 최대 256자, 한글 2byte 이외는 1byte 공백도 1byte)</font></span>
                        </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        {
                            commentState === 'W' 
                            ? 
                            <>
                                <Button variant="outlined" size="small" color="primary" onClick={onClickCommentComplete}>완료</Button>
                                <Button variant="outlined" size="small" color="primary" onClick={onClickCommentCancel}>취소</Button>
                            </>
                            :
                            <>
                                <Button variant="outlined" size="small" color="primary" onClick={onClickCommentWrite}>첨삭하기</Button>
                                {
                                    commentValue !== "" ? <Button variant="outlined" size="small" color="primary" onClick={onClickCommentDelete}>삭제</Button> : <></> 
                                }
                            </>
                            
                        }
                        
                    </CardActions>
                </Card>

                </Item>
            </Stack>
            
            </div>
        </div>

        <div className={styles.blockCoding_container}>
        {
            props.mission && props.mission.mission.chapter.length !== 0
            ? 
            props.mission.mission.chapter
            .map((block, index) => {
                return (
                    <>
                    
                    <div className={styles.blockCoding_inner}>
                        <div className={styles.blockCoding_chapter}>
                            Chapter{index+1}
                        </div>
                        {/* 중첩된 배열을 반환 */}
                        {
                            block.block.map((char, index2) => 
                            (
                                char.block_type === "block" ?
                                <div className={styles.blockCoding_block}>
                                    {
                                        {
                                        "CodeBlock_0001" : <div className={styles.CodeBlock_0001}>{/* Move forward {} sqaure(s) */}
                                            <div className={styles.blockCoding_space}></div>{/* 앞으로 <input type="text" size="2" maxlength="2" value={char.block_value} className={styles.CodeBlock_input_style} />칸 이동 */}
                                            {t("ClassHomework.CodeBlock_0001_before")} &nbsp;<input type="text" size="2" maxlength="2" value={char.block_value} className={styles.CodeBlock_input_style} /> {t("ClassHomework.CodeBlock_0001_after")}
                                        </div>,
                                        "CodeBlock_0002" : <div className={styles.CodeBlock_0002}>{/* Move backword {} sqaure(s) */}
                                            <div className={styles.blockCoding_space}></div>{/* 뒤로 <input type="text" size="2" maxlength="2" value={char.block_value} className={styles.CodeBlock_input_style} />칸 이동 */}
                                            {t("ClassHomework.CodeBlock_0002_before")} &nbsp;<input type="text" size="2" maxlength="2" value={char.block_value} className={styles.CodeBlock_input_style} /> {t("ClassHomework.CodeBlock_0002_after")}
                                        </div>,
                                        "CodeBlock_0003" : <div className={styles.CodeBlock_0003}>{/* Turn right */}
                                            <div className={styles.blockCoding_space2}></div> {/* 오른쪽으로 회전 */}{t("ClassHomework.CodeBlock_0003")}
                                        </div>,
                                        "CodeBlock_0004" : <div className={styles.CodeBlock_0004}>{/* Turn left */}
                                            <div className={styles.blockCoding_space2}></div> {/* 왼쪽으로 회전 */}{t("ClassHomework.CodeBlock_0004")}
                                        </div>,
                                        "CodeBlock_0005" : <div className={styles.CodeBlock_0005}>{/* Turn right {} degree(s) */}
                                            <div className={styles.blockCoding_space}></div> {/* 오른쪽으로 <input type="text" size="3" maxlength="3" value={char.block_value} className={styles.CodeBlock_input_style} /> 도 만큼 회전 */}
                                            {t("ClassHomework.CodeBlock_0005_before")} &nbsp;<input type="text" size="3" maxlength="3" value={char.block_value} className={styles.CodeBlock_input_style} /> {t("ClassHomework.CodeBlock_0005_after")}
                                        </div>,
                                        "CodeBlock_0006" : <div className={styles.CodeBlock_0006}>{/* Turn left {} degree(s) */}
                                            <div className={styles.blockCoding_space}></div> {/* 왼쪽으로 <input type="text" size="3" maxlength="3" value={char.block_value} className={styles.CodeBlock_input_style} /> 도 만큼 회전 */}
                                            {t("ClassHomework.CodeBlock_0006_before")} &nbsp;<input type="text" size="3" maxlength="3" value={char.block_value} className={styles.CodeBlock_input_style} /> {t("ClassHomework.CodeBlock_0006_after")}
                                        </div>,
                                        "CodeBlock_0007" : <div className={styles.CodeBlock_0007}>{/* Jump forward */}
                                            <div className={styles.blockCoding_space2}></div> {/* 앞으로 점프 */}{t("ClassHomework.CodeBlock_0007")}
                                        </div>,
                                        "CodeBlock_0008" : <div className={styles.CodeBlock_0008}>{/* Jump in place */}
                                            <div className={styles.blockCoding_space2}></div> {/* 제자리 점프 */}{t("ClassHomework.CodeBlock_0008")}
                                        </div>,
                                        "CodeBlock_0009" : <div className={styles.CodeBlock_0009}>{/* Get items */}
                                            <div className={styles.blockCoding_space2}></div> {/* 아이템 획득 */}{t("ClassHomework.CodeBlock_0009")}
                                        </div>,
                                        "CodeBlock_0010" : <div className={styles.CodeBlock_0010}>{/* Run width */}
                                            <div className={styles.blockCoding_space}></div> {/* <input type="text" size="2" maxlength="2" value={char.block_value} className={styles.CodeBlock_input_style} /> 의 힘으로 달려가기 */}
                                            <input type="text" size="2" maxlength="2" value={char.block_value} className={styles.CodeBlock_input_style} /> {t("ClassHomework.CodeBlock_0010")}
                                        </div>,
                                        "CodeBlock_0011" : <div className={styles.CodeBlock_0011}>{/* Pick up the thing */}
                                            <div className={styles.blockCoding_space2}></div> {/* 물체를 잡기 */}{t("ClassHomework.CodeBlock_0011")}
                                        </div>,
                                        "CodeBlock_0012" : <div className={styles.CodeBlock_0012}>{/* Put down the thing */}
                                            <div className={styles.blockCoding_space2}></div> {/* 물체를 놓기 */}{t("ClassHomework.CodeBlock_0012")}
                                        </div>,
                                        "CodeBlock_0013" : <div className={styles.CodeBlock_0013}>{/* Pull lever */}
                                            <div className={styles.blockCoding_space2}></div> {/* 레버 당기기 */}{t("ClassHomework.CodeBlock_0013")}
                                        </div>,
                                        "CodeBlock_0015" : <div className={styles.CodeBlock_0015}>{/* Goes down the ladder () step(s) */}
                                            <div className={styles.blockCoding_space}></div> {/* 사다리 <input type="text" size="2" maxlength="2" value={char.block_value} className={styles.CodeBlock_input_style} /> 칸 올라가기 */}
                                            {t("ClassHomework.CodeBlock_0015_before")} &nbsp;<input type="text" size="2" maxlength="2" value={char.block_value} className={styles.CodeBlock_input_style} /> {t("ClassHomework.CodeBlock_0015_after")}
                                        </div>,
                                        "CodeBlock_0016" : <div className={styles.CodeBlock_0016}>{/* Goes up the ladder () step(s) */}
                                            <div className={styles.blockCoding_space}></div> {/* 사다리 <input type="text" size="2" maxlength="2" value={char.block_value} className={styles.CodeBlock_input_style} /> 칸 내려가기 */}
                                            {t("ClassHomework.CodeBlock_0016_before")} &nbsp;<input type="text" size="2" maxlength="2" value={char.block_value} className={styles.CodeBlock_input_style} /> {t("ClassHomework.CodeBlock_0016_after")}
                                        </div>,
                                    }[char.block_ID]}
                                </div>
                                :
                                <div className={styles.blockCoding_repeat}>
                                    <div className={styles.blockCoding_repeat_top}>{/* Repeat {} time(s) */}
                                    {t("ClassHomework.CodeBlock_repeat_before")} &nbsp;<input type="text" size="2" maxlength="2" value={char.block_value} className={styles.CodeBlock_input_style} />{/* 회 반복하기 */}{t("ClassHomework.CodeBlock_repeat_after")}
                                    </div>
                                    <div className={styles.blockCoding_repeat_middle}>
                                        <div className={styles.blockCoding_repeat_inner_left}>
                                            
                                        </div>
                                        <div className={styles.blockCoding_repeat_inner_right}>
                                        
                                        {char.repeat.map((char2, index3) => (
                                            <div className={styles.blockCoding_block}>
                                                {
                                                    {
                                                        "CodeBlock_0001" : <div className={styles.CodeBlock_0001}>{/* Move forward {} sqaure(s) */}
                                                        <div className={styles.blockCoding_space}></div>{/* 앞으로 <input type="text" size="2" maxlength="2" value={char2.block_value} className={styles.CodeBlock_input_style} />칸 이동 */}
                                                        {t("ClassHomework.CodeBlock_0001_before")} &nbsp;<input type="text" size="2" maxlength="2" value={char2.block_value} className={styles.CodeBlock_input_style} /> {t("ClassHomework.CodeBlock_0001_after")}
                                                    </div>,
                                                    "CodeBlock_0002" : <div className={styles.CodeBlock_0002}>{/* Move backword {} sqaure(s) */}
                                                        <div className={styles.blockCoding_space}></div>{/* 뒤로 <input type="text" size="2" maxlength="2" value={char2.block_value} className={styles.CodeBlock_input_style} />칸 이동 */}
                                                        {t("ClassHomework.CodeBlock_0002_before")} &nbsp;<input type="text" size="2" maxlength="2" value={char2.block_value} className={styles.CodeBlock_input_style} /> {t("ClassHomework.CodeBlock_0002_after")}
                                                    </div>,
                                                    "CodeBlock_0003" : <div className={styles.CodeBlock_0003}>{/* Turn right */}
                                                        <div className={styles.blockCoding_space2}></div> {/* 오른쪽으로 회전 */}{t("ClassHomework.CodeBlock_0003")}
                                                    </div>,
                                                    "CodeBlock_0004" : <div className={styles.CodeBlock_0004}>{/* Turn left */}
                                                        <div className={styles.blockCoding_space2}></div> {/* 왼쪽으로 회전 */}{t("ClassHomework.CodeBlock_0004")}
                                                    </div>,
                                                    "CodeBlock_0005" : <div className={styles.CodeBlock_0005}>{/* Turn right {} degree(s) */}
                                                        <div className={styles.blockCoding_space}></div> {/* 오른쪽으로 <input type="text" size="3" maxlength="3" value={char2.block_value} className={styles.CodeBlock_input_style} /> 도 만큼 회전 */}
                                                        {t("ClassHomework.CodeBlock_0005_before")} &nbsp;<input type="text" size="3" maxlength="3" value={char2.block_value} className={styles.CodeBlock_input_style} /> {t("ClassHomework.CodeBlock_0005_after")}
                                                    </div>,
                                                    "CodeBlock_0006" : <div className={styles.CodeBlock_0006}>{/* Turn left {} degree(s) */}
                                                        <div className={styles.blockCoding_space}></div> {/* 왼쪽으로 <input type="text" size="3" maxlength="3" value={char2.block_value} className={styles.CodeBlock_input_style} /> 도 만큼 회전 */}
                                                        {t("ClassHomework.CodeBlock_0006_before")} &nbsp;<input type="text" size="3" maxlength="3" value={char2.block_value} className={styles.CodeBlock_input_style} /> {t("ClassHomework.CodeBlock_0006_after")}
                                                    </div>,
                                                    "CodeBlock_0007" : <div className={styles.CodeBlock_0007}>{/* Jump forward */}
                                                        <div className={styles.blockCoding_space2}></div> {/* 앞으로 점프 */}{t("ClassHomework.CodeBlock_0007")}
                                                    </div>,
                                                    "CodeBlock_0008" : <div className={styles.CodeBlock_0008}>{/* Jump in place */}
                                                        <div className={styles.blockCoding_space2}></div> {/* 제자리 점프 */}{t("ClassHomework.CodeBlock_0008")}
                                                    </div>,
                                                    "CodeBlock_0009" : <div className={styles.CodeBlock_0009}>{/* Get items */}
                                                        <div className={styles.blockCoding_space2}></div> {/* 아이템 획득 */}{t("ClassHomework.CodeBlock_0009")}
                                                    </div>,
                                                    "CodeBlock_0010" : <div className={styles.CodeBlock_0010}>{/* Run width */}
                                                        <div className={styles.blockCoding_space}></div> {/* <input type="text" size="2" maxlength="2" value={char2.block_value} className={styles.CodeBlock_input_style} /> 의 힘으로 달려가기 */}
                                                        <input type="text" size="2" maxlength="2" value={char2.block_value} className={styles.CodeBlock_input_style} /> {t("ClassHomework.CodeBlock_0010")}
                                                    </div>,
                                                    "CodeBlock_0011" : <div className={styles.CodeBlock_0011}>{/* Pick up the thing */}
                                                        <div className={styles.blockCoding_space2}></div> {/* 물체를 잡기 */}{t("ClassHomework.CodeBlock_0011")}
                                                    </div>,
                                                    "CodeBlock_0012" : <div className={styles.CodeBlock_0012}>{/* Put down the thing */}
                                                        <div className={styles.blockCoding_space2}></div> {/* 물체를 놓기 */}{t("ClassHomework.CodeBlock_0012")}
                                                    </div>,
                                                    "CodeBlock_0013" : <div className={styles.CodeBlock_0013}>{/* Pull lever */}
                                                        <div className={styles.blockCoding_space2}></div> {/* 레버 당기기 */}{t("ClassHomework.CodeBlock_0013")}
                                                    </div>,
                                                    "CodeBlock_0015" : <div className={styles.CodeBlock_0015}>{/* Goes down the ladder () step(s) */}
                                                        <div className={styles.blockCoding_space}></div> {/* 사다리 <input type="text" size="2" maxlength="2" value={char2.block_value} className={styles.CodeBlock_input_style} /> 칸 올라가기 */}
                                                        {t("ClassHomework.CodeBlock_0015_before")} &nbsp;<input type="text" size="2" maxlength="2" value={char2.block_value} className={styles.CodeBlock_input_style} /> {t("ClassHomework.CodeBlock_0015_after")}
                                                    </div>,
                                                    "CodeBlock_0016" : <div className={styles.CodeBlock_0016}>{/* Goes up the ladder () step(s) */}
                                                        <div className={styles.blockCoding_space}></div> {/* 사다리 <input type="text" size="2" maxlength="2" value={char2.block_value} className={styles.CodeBlock_input_style} /> 칸 내려가기 */}
                                                        {t("ClassHomework.CodeBlock_0016_before")} &nbsp;<input type="text" size="2" maxlength="2" value={char2.block_value} className={styles.CodeBlock_input_style} /> {t("ClassHomework.CodeBlock_0016_after")}
                                                    </div>,
                                                }[char2.block_ID]}
                                            </div>
                                        ))}

                                        </div>
                                    </div>
                                    <div className={styles.blockCoding_repeat_bottom}></div>
                                </div>
                            ))
                        }
                    </div>
                    { props.mission.mission.chapter.length > 1 && index !== props.mission.mission.chapter.length - 1 ?
                    <div className={styles.blockCoding_bridge}>
                        <img src='/assets/icons8-두-번-오른쪽-100.png' />
                    </div>
                    :
                    <></>
                    }
                </>);
                
            })
            :
            <></>
        }

        </div>

        {/* 중첩된 배열을 반환 */}
        {/* {mission.mission.chapter[index].block[index2].repeat.map((char2, index3) => (
                                                            <div className={styles.blockCoding_block}>
                                                            {
                                                                {
                                                                "CodeBlock_0001" : <div className={styles.CodeBlock_0001}>{console.log(char)}
                                                                    <div className={styles.blockCoding_space}></div>앞으로 <input size="2" maxlength="2" value={char2.block_value} />칸 이동
                                                                </div>,
                                                                "CodeBlock_0002" : <div className={styles.CodeBlock_0002}>
                                                                    <div className={styles.blockCoding_space}></div>뒤로 <input type="text" size="2" maxlength="2" value={char2.block_value} />칸 이동
                                                                </div>,
                                                                "CodeBlock_0003" : <div className={styles.CodeBlock_0003}>
                                                                    <div className={styles.blockCoding_space}></div>오른쪽으로 회전
                                                                </div>,

                                                            }[char2.block_ID]}
                                                            </div>
                                                        ))} */}


{/* <div className={styles.blockCoding_block}>
                                                {
                                                    {
                                                    "CodeBlock_0001" : <div className={styles.CodeBlock_0001}>
                                                        <div className={styles.blockCoding_space}></div>앞으로 <input size="2" maxlength="2" value={char.block_value} />칸 이동
                                                    </div>,
                                                    "CodeBlock_0002" : <div className={styles.CodeBlock_0002}>
                                                        <div className={styles.blockCoding_space}></div>뒤로 <input type="text" size="2" maxlength="2" value={char.block_value} />칸 이동
                                                    </div>,
                                                    "CodeBlock_0003" : <div className={styles.CodeBlock_0003}>
                                                        <div className={styles.blockCoding_space}></div>오른쪽으로 회전
                                                    </div>,

                                                }[char.block_ID]}
                                            </div>
                                            : 
                                            <div className={styles.blockCoding_repeat}>
                                                    <div className={styles.blockCoding_repeat_top}>
                                                        <input type="text" size="2" maxlength="2" value={char.block_value} />회 반복하기
                                                    </div>
                                                    <div className={styles.blockCoding_repeat_middle}>
                                                        <div className={styles.blockCoding_repeat_inner_left}>
                                                            
                                                        </div>
                                                        <div className={styles.blockCoding_repeat_inner_right}>
                                                        {console.log("repeat in char => ", char)} */}
                                                        {/* <div className={styles.blockCoding_block}>
                                                            <div className={styles.CodeBlock_0002}>
                                                                <div className={styles.blockCoding_space}></div>뒤로 <input type="text" size="2" maxlength="2" value="1" />칸 이동
                                                            </div>
                                                        </div> */}

                                                        {/* {char && char.repeat.length !== 0 && char.repeat.map((char2, index3) => (
                                                            <div className={styles.blockCoding_block}>
                                                                <div className={styles.CodeBlock_0002}>
                                                                    <div className={styles.blockCoding_space}></div>뒤로 <input type="text" size="2" maxlength="2" value="1" />칸 이동
                                                                </div>
                                                            </div>
                                                        ))}

                                                        </div>
                                                    </div>
                                                    <div className={styles.blockCoding_repeat_bottom}></div>
                                                </div> */}

      {/* <div className={styles.blockCoding_container}>

        <div className={styles.blockCoding_inner}>
            <div className={styles.blockCoding_chapter}>
                Chapter1
            </div>
            <div className={styles.blockCoding_block}>
                <div className={styles.CodeBlock_0001}>
                    <div className={styles.blockCoding_space}></div>앞으로 <input size="2" maxlength="2" value="1" />칸 이동
                </div>
            </div>
            <div className={styles.blockCoding_repeat}>
                <div className={styles.blockCoding_repeat_top}>
                    <input type="text" size="2" maxlength="2" value="1" />회 반복하기
                </div>
                <div className={styles.blockCoding_repeat_middle}>
                    <div className={styles.blockCoding_repeat_inner_left}>
                        
                    </div>
                    <div className={styles.blockCoding_repeat_inner_right}> */}
                        {/* repeat block start */}
                        {/* <div className={styles.blockCoding_block}>
                            <div className={styles.CodeBlock_0002}>
                                <div className={styles.blockCoding_space}></div>뒤로 <input type="text" size="2" maxlength="2" value="1" />칸 이동
                            </div>
                        </div>
                        <div className={styles.blockCoding_block}>
                            <div className={styles.CodeBlock_0003}>
                                <div className={styles.blockCoding_space}></div>오른쪽으로 회전
                            </div>
                        </div> */}
                        {/* repeat block end */}
                    {/* </div>
                </div>
                <div className={styles.blockCoding_repeat_bottom}></div>
            </div>
            <div className={styles.blockCoding_block}>
                <div className={styles.CodeBlock_0004}>
                    <div className={styles.blockCoding_space}></div>왼쪽으로 회전
                </div>
            </div>
        </div>

        <div className={styles.blockCoding_bridge}>
            <img src='/assets/icons8-두-번-오른쪽-100.png' />
        </div>

        <div className={styles.blockCoding_inner}>
    <div className={styles.blockCoding_chapter}>
        Chapter4
    </div>
    <div className={styles.blockCoding_block}>
        <div className={styles.CodeBlock_0005}>
            <div className={styles.blockCoding_space}></div> 오른쪽으로 <input type="text" size="3" maxlength="3" value="90" /> 도 회전
        </div>
    </div>
    <div className={styles.blockCoding_repeat}>
        <div className={styles.blockCoding_repeat_top}>
            <input type="text" size="2" maxlength="2" value="1" />회 반복하기
        </div>
        <div className={styles.blockCoding_repeat_middle}>
            <div className={styles.blockCoding_repeat_inner_left}>
                
            </div>
            <div className={styles.blockCoding_repeat_inner_right}> */}
                {/* repeat block start */}
                {/* <div className={styles.blockCoding_block}>
                    <div className={styles.CodeBlock_0006}>
                        <div className={styles.blockCoding_space}></div> 왼쪽으로 <input type="text" size="3" maxlength="3" value="90" /> 도 회전
                    </div>
                </div>
                <div className={styles.blockCoding_block}>
                    <div className={styles.CodeBlock_0007}>
                        <div className={styles.blockCoding_space}></div> 앞으로 점프
                    </div>
                </div>
                <div className={styles.blockCoding_block}>
                    <div className={styles.CodeBlock_0008}>
                        <div className={styles.blockCoding_space}></div> 제자리 점프
                    </div>
                </div>
                <div className={styles.blockCoding_block}>
                    <div className={styles.CodeBlock_0011}>
                        <div className={styles.blockCoding_space}></div> 물체를 잡기
                    </div>
                </div>
                <div className={styles.blockCoding_block}>
                    <div className={styles.CodeBlock_0012}>
                        <div className={styles.blockCoding_space}></div> 물체를 놓기
                    </div>
                </div>
                <div className={styles.blockCoding_block}>
                    <div className={styles.CodeBlock_0013}>
                        <div className={styles.blockCoding_space}></div> 레버 당기기
                    </div>
                </div> */}
                {/* repeat block end */}
            {/* </div>
        </div>
        <div className={styles.blockCoding_repeat_bottom}></div>
    </div>
    <div className={styles.blockCoding_block}>
        <div className={styles.CodeBlock_0009}>
            <div className={styles.blockCoding_space}></div> 아이템 획득
        </div>
    </div>
    <div className={styles.blockCoding_block}>
        <div className={styles.CodeBlock_0010}>
            <div className={styles.blockCoding_space}></div> <input type="text" size="2" maxlength="2" value="1" /> 의 힘으로 달려가기
        </div>
    </div>
    <div className={styles.blockCoding_block}>
        <div className={styles.CodeBlock_0011}>
            <div className={styles.blockCoding_space}></div> 물체를 잡기
        </div>
    </div>
    <div className={styles.blockCoding_block}>
        <div className={styles.CodeBlock_0012}>
            <div className={styles.blockCoding_space}></div> 물체를 놓기
        </div>
    </div>
    <div className={styles.blockCoding_block}>
        <div className={styles.CodeBlock_0013}>
            <div className={styles.blockCoding_space}></div> 레버 당기기
        </div>
    </div>
    <div className={styles.blockCoding_block}>
        <div className={styles.CodeBlock_0015}>
            <div className={styles.blockCoding_space}></div> 사다리 <input type="text" size="2" maxlength="2" value="1" /> 칸 올라가기
        </div>
    </div>
    <div className={styles.blockCoding_block}>
        <div className={styles.CodeBlock_0016}>
            <div className={styles.blockCoding_space}></div> 사다리 <input type="text" size="2" maxlength="2" value="1" /> 칸 내려가기
        </div>
    </div>
    <div className={styles.blockCoding_block}>
        <div className={styles.CodeBlock_0007}>
            <div className={styles.blockCoding_space}></div> 앞으로 점프
        </div>
    </div>
    <div className={styles.blockCoding_block}>
        <div className={styles.CodeBlock_0008}>
            <div className={styles.blockCoding_space}></div> 제자리 점프
        </div>
    </div>
  </div>

      </div> */}
        
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          닫기
        </Button>
        {/* <Button onClick={handleOk}>Ok</Button> */}
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

export default function ConfirmationDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [mission, setMission] = React.useState(null);
  const [studentScore, setStudentScore] = React.useState(null);
  const [missionScore, setMissionScore] = React.useState(null);

  const dispatch = useDispatch();	

  const { t, i18n } = useTranslation(); // 3. useTranslation hook 선언

  const handleClickOpen = () => {
    setOpen(true);

    // console.log(props.RowData);

    let sendData = {
		hm_idx: props.RowData.hm_idx,
		mst_idx: props.mstIdx,
		mis_idx: props.RowData.mis_idx,
	}

	// 창이 열릴 때 데이터를 불러온다. (화면에 표시되는 데이터를 편집하기 위한 데이터)
	dispatch(classHomeworkSelectAll(sendData))
	.then(data => 
	{
		// console.log("classHomeworkSelectAll data : ", data);

        // console.log(data.studentScore[0]);
        // console.log(data.studentMissionScore[0]);
        // console.log(data.homeworkMission.mis_idx);
        // console.log(data.homeworkMission.mission);

        let mission = data.homeworkMission;

        // console.log("mission => ", mission);

        setMission(mission);
        setStudentScore(data.studentScore[0]);
        setMissionScore(data.studentMissionScore[0]);

        // 받아온 데이터를 해석해서 그려주는 루틴
        // for (let i=0; i<mission.mission.chapter.length; i++)
        // {
        //     console.log("chapter" + i);

        //     for (let j=0; j<mission.mission.chapter[i].block.length; j++)
        //     {
        //         console.log("   block" + j);
        //         console.log("       block type : " + mission.mission.chapter[i].block[j].block_type);

        //         if (mission.mission.chapter[i].block[j].block_type === "block")
        //         {
        //             console.log("       block");
        //             console.log("       block orderby : " + mission.mission.chapter[i].block[j].block_orderby);
        //             console.log("       block ID : " + mission.mission.chapter[i].block[j].block_ID);
        //             console.log("       block value : " + mission.mission.chapter[i].block[j].block_value);
        //             console.log("       block type : " + mission.mission.chapter[i].block[j].block_type);

        //         }
        //         else if (mission.mission.chapter[i].block[j].block_type === "repeat")
        //         {
        //             console.log("       repeat");
        //             console.log("       block orderby : " + mission.mission.chapter[i].block[j].block_orderby);
        //             console.log("       block ID : " + mission.mission.chapter[i].block[j].block_ID);
        //             console.log("       block value : " + mission.mission.chapter[i].block[j].block_value);
        //             console.log("       block type : " + mission.mission.chapter[i].block[j].block_type);

        //             for (let k=0; k<mission.mission.chapter[i].block[j].repeat.length; k++)
        //             {
        //                 console.log("           block orderby : " + mission.mission.chapter[i].block[j].repeat[k].block_orderby);
        //                 console.log("           block ID : " + mission.mission.chapter[i].block[j].repeat[k].block_ID);
        //                 console.log("           block value : " + mission.mission.chapter[i].block[j].repeat[k].block_value);
        //             }
        //         }
        //     }
        // }
	})
	.catch(e => {
		console.log(e);
	});
    
  };

  const handleClose = (newValue) => {
    setOpen(false);
    setMission(null);

    let sendData = {
		mc_idx:props.RowData.mc_idx,
		mst_idx: props.mstIdx,
		category_idx: props.categoryIdx || "",
		level: props.levelValue || "",
	}

	// 창이 열릴 때 데이터를 불러온다. (화면에 표시되는 데이터를 편집하기 위한 데이터)
	dispatch(classHomeworkListAll(sendData))
	.then(data => 
	{
		// console.log("classHomeworkListAll data : ", data);
	})
	.catch(e => {
		console.log(e);
	});
  };

  return (
    <Box sx={{ bgcolor: 'background.paper' }}>
      <Button variant="contained" size='small' onClick={handleClickOpen}>검사</Button>
      <ConfirmationDialogRaw
          id="ringtone-menu"
          keepMounted
          open={open}
          onClose={handleClose}
          mission={mission}
          RowData={props.RowData}
          mstIdx={props.mstIdx}
          studentScore={studentScore}
          missionScore={missionScore}
        />
    </Box>
  );
}
